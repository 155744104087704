<template>
  <div id="tv">
    <img :src="url" v-if="mediaType===0">
    <swiper :options="swiperOption" v-if="mediaType===1">
      <swiper-slide v-for="(item,index) in url.split(',')" :key="index">
        <div class="swiper-img" :style="{ 'background-image': 'url(' + item + ')' }"></div>
      </swiper-slide>
    </swiper>
    <video style=" height: 100vh;
    width: 100vw;" v-if="mediaType===2" :src="url" autoplay preload loop muted
           controls></video>
  </div>
</template>
<script>

import {getTvShow, getTvVersion} from "@/api/tvshow";

export default {
  name: "tvPage",
  data() {
    return {
      type: 3,
      screenHeight: 0, // 屏幕高度
      swiperOption: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        initialSlide: 1,
        slidesPerGroup: 1,
        loop: true,
        onSlideChangeEnd: swiper => {
          console.log('onSlideChangeEnd', swiper.realIndex)
        }
      },
      url: '',
      mediaType: null,
      version: null,
    }
  },
  created: function () {
    let that = this;
    this.marketId = this.$route.params.marketId
    this.loadTvShow(this.marketId)

    setInterval(function () {
      // 每5分钟查询一次版本
      that.loadVersion()
    }, 1000 * 6 * 1)
  },
  methods: {
    loadTvShow() {
      getTvShow(this.marketId).then(res => {
        if (res.success) {
          this.url = res.data.url
          this.mediaType = res.data.mediaType
        }else {
          this.mediaType = -1;
          this.url = '';
        }
      })
    },
    loadVersion() {
      getTvVersion(this.marketId).then(res => {
        if (!res.success) {
          this.mediaType = -1;
          this.url = '';
          return
        }
        if (res.data === this.version) {
          // 版本号一致 不更新数据
        } else {
          // 同步版本更新
          this.version = res.data
          this.loadTvShow()
        }
      })
    }
  }
};
</script>
<style lang="scss">
html {
  font-size: 0 !important;
}

#tv {
  overflow: hidden;

  img {
    height: 100vh;
    width: 100vw;
  }

  .swiper-img {
    height: 100vh;
    width: 100vw;
    background-repeat: repeat;
    background-size: 100% 100%;
  }
}
</style>