import { request } from '@/utils/http'

export const getTvShow = (marketId) => {
    return request({
        url:  '/anon/tvshow/' + marketId,
        method: 'get'
    })
}

export const getTvVersion = (marketId) => {
    return request({
        url:  '/anon/tvshow/version/' + marketId,
        method: 'get'
    })
}
